var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tab-content", style: [{ width: _vm.width }] },
    _vm._l(_vm.tabItems, function (item, $index) {
      return _c(
        "div",
        {
          key: $index,
          staticClass: "tab",
          class: { isAcitve: _vm.isActive === item.id },
          on: {
            click: function ($event) {
              return _vm.tabChange($index, item)
            },
          },
        },
        [_vm._v(" " + _vm._s(item.name) + " ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }