<template>
  <div class="tab-content" :style="[{ width: width }]">
    <div
      v-for="(item, $index) in tabItems"
      :key="$index"
      class="tab"
      :class="{ isAcitve: isActive === item.id }"
      @click="tabChange($index, item)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabItems: {
      type: Array,
      default: function() {
        return []
      }
    },
    propWidth: {
      type: Number,
      default: 0
    },
    active: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isActive: 0
    }
  },
  computed: {
    width: function() {
      if (this.propWidth) {
        return `${this.propWidth}px`
      }
      return `${this.tabItems.length * 165}px`
    }
  },
  watch: {
    active(v) {
      this.isActive = v
    }
  },
  methods: {
    tabChange(index, item) {
        this.isActive = index
        this.$emit('tabChange', item)
    }
  }
}
</script>

<style lang="less" scoped>
.tab-content {
  width: auto;
  height: 40px;
  display: flex;
  justify-content: center;
  background: #f7f7f7;
  margin: 0 auto;
  position: relative;
  padding: 5px;
  border-radius: 4px 4px 4px 4px;
  overflow: hidden;
  box-sizing: border-box;
}
.tab {
  flex-basis: 100%;
  height: 30px;
  background: transparent;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}
.isAcitve {
  background: #fff;
  color: #2395f3;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.05);
  border-radius: 4px 4px 4px 4px;
}
</style>
